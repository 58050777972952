<template>
  <v-container class="py-4" fluid>
    <v-row class="mx-2 mb-4 text-h6 font-weight-black align-center" no-gutters>
      <v-icon class="mr-3">mdi-text-box-search-outline</v-icon>
      Registros
    </v-row>

    <sub-navigation :routes="routes" class="mb-4" />

    <Filters
      class="mb-2"
      :dataFilters="require('@/assets/filters/logs.json')"
      @apply-filter="applyFilter"
    />

    <section>
      <!-- summary and filter status  -->
      <v-chip-group v-model="status" class="mb-2" column @change="getData()">
        <v-chip
          v-for="(item, i) in statusFilter"
          color="transparent"
          class="mr-1"
          :active-class="`${item.color}--text`"
          :key="i"
          :value="item.value"
          small
        >
          <v-icon :color="item.color" left small> mdi-square-rounded </v-icon>

          <span class="text-caption">
            {{ item.label }}
          </span>
        </v-chip>
      </v-chip-group>

      <!-- table -->
      <v-card class="rounded-lg" outlined>
        <v-data-table
          class="accent pointer-hand"
          item-key="_id"
          :headers="headers"
          :items="logs"
          :items-per-page="pagination.itemsPerPage"
          :mobile-breakpoint="0"
          :item-class="rowClasses"
          :custom-sort="pagination.customSort"
          :expanded.sync="expanded"
          hide-default-footer
          show-expand
        >
          <!-- id -->
          <template v-slot:[`item._id`]="{ item }">
            {{ idMask(item._id) }}
          </template>

          <!-- subject -->
          <template v-slot:[`item.subject`]="{ item }">
            <span v-if="item.subject || item.subjectType">
              <p class="mb-0 single-line">
                <span v-if="item.subject">
                  {{ idMask(item.subject) }}
                </span>

                <span v-else>~</span>
              </p>

              <p class="mb-0 text-caption single-line">
                {{ getSubject(item.subjectType) }}
              </p>
            </span>

            <span v-else>-</span>
          </template>

          <!-- identifier -->
          <template v-slot:[`item.identifier`]="{ item }">
            <span v-if="item.identifier || item.identifierType">
              <p class="mb-0 single-line">
                <span v-if="item.identifier">
                  {{ idMask(item.identifier) }}
                </span>

                <span v-else>~</span>
              </p>

              <p class="mb-0 text-caption single-line">
                {{ getIdentifier(item.identifierType) }}
              </p>
            </span>

            <span v-else>~</span>
          </template>

          <!-- adicional -->
          <template v-slot:[`item.childIdentifier`]="{ item }">
            <span v-if="item.childIdentifier || item.childType">
              <p class="mb-0">
                <span v-if="item.childIdentifier">
                  {{ idMask(item.childIdentifier) }}
                </span>

                <span v-else>~</span>
              </p>

              <p class="mb-0 text-caption single-line">
                {{ getChild(item.childType) }}
              </p>
            </span>

            <span v-else>~</span>
          </template>

          <!-- created -->
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <!-- expand -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <span class="secondary_text--text"> log: </span>

              <span class="mr-6">
                {{ item.errorLog || "empty" }}
              </span>

              <span class="secondary_text--text"> message: </span>

              <span>
                {{ item.errorMessage || "empty" }}
              </span>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
        v-model="pagination.page"
        class="py-4"
        color="primary"
        :length="pagination.pageCount"
        :total-visible="10"
        circle
        @input="getData()"
      />
    </section>

    <loader-hover v-if="loading" />
  </v-container>
</template>

<script>
import { getLogs } from "@/services/system";
import { displayAlert, formatDate } from "@/utils";
import Filters from "@/components/filters/Filters";

export default {
  data() {
    return {
      interval: null,
      loading: true,
      routes: [
        { label: "Todos", path: "/logs" },
        { label: "Sistema", path: "/logs/system" },
        { label: "Usuário", path: "/logs/user" },
        { label: "Cliente", path: "/logs/customer" },
      ],
      statusFilter: [
        {
          label: "Sucesso",
          color: "green",
          value: "false",
        },
        {
          label: "Erro",
          color: "red",
          value: "true",
        },
      ],
      expanded: [],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        sort: "createdAt,desc",
      },
      headers: [
        { text: "Resp.", value: "subject" },
        { text: "Identif.", value: "identifier" },
        { text: "Descrição", value: "description" },
        { text: "Adicional", value: "childIdentifier", width: 130 },
        { text: "Data", value: "createdAt" },
      ],
      logs: [],
      filter: [],
      status: "",
    };
  },

  components: {
    Filters,
  },

  beforeMount() {
    this.getData();

    this.interval = setInterval(() => {
      if (!this.loading) this.getData();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  watch: {
    ["$route"]() {
      this.loading = true;
      this.pagination.page = 1;

      this.getData();
    },
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: String(this.pagination.page - 1),
          size: this.pagination.itemsPerPage,
          sort: this.pagination.sort,
          subjectType: this.$route.params.method
            ? this.$route.params.method.toUpperCase()
            : "",
          isError: this.status,
          ...this.filter,
        };

        await getLogs(payload).then((res) => {
          this.logs = res.body.content;
          this.pagination.pageCount = res.body.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    // return row status color
    rowClasses(item) {
      return `${item.isError ? "red" : "green"}--text text--${
        this.$vuetify.theme.dark ? "lighten-2" : "darken-2"
      }`;
    },

    // return label of subject
    getSubject(value) {
      switch (value) {
        case "SYSTEM":
          return "Sistema";
        case "USER":
          return "Usuário";
        case "CUSTOMER":
          return "Cliente";
        default:
          return "";
      }
    },

    // return label of method
    getIdentifier(value) {
      switch (value) {
        case "TRANSACTION":
          return "Transação";
        case "USER":
          return "Usuário";
        case "CUSTOMER":
          return "Cliente";
        case "COMPANY":
          return "Empresa";
        case "BANK_ACCOUNT":
          return "Conta Bancária";
        default:
          return "";
      }
    },

    // return label of child
    getChild(value) {
      switch (value) {
        case "BOLETO":
          return "Boleto";
        case "CARD":
          return "Cartão";
        case "PICPAY":
          return "PicPay";
        case "PIX":
          return "Pix";
        case "WITHDRAW":
          return "Saque";
        case "TRANSFER":
          return "Transferência";
        case "FILE":
          return "Comprovante";
        default:
          return "";
      }
    },

    // mask on uuid
    idMask(value = "") {
      value = value.toString();

      return value.length > 12
        ? `${value.substring(0, 5)}...${value.substring(value.length - 5)}`
        : value;
    },

    // get data with filter
    applyFilter(value) {
      this.loading = true;
      this.filter = value;
      this.pagination.page = 1;

      this.getData();
    },

    // sort table
    customSort(items, sortBy, sortDesc) {
      let value = sortBy[0];
      let sort = "";

      if (value) sort = value + "," + (sortDesc[0] ? "desc" : "asc");
      else sort = "createdAt,desc";

      if (sort !== this.pagination.sort) {
        this.pagination.sort = sort;
        this.getData();
      }

      return items;
    },

    displayAlert,

    formatDate,
  },
};
</script>