var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-2 mb-4 text-h6 font-weight-black align-center",attrs:{"no-gutters":""}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-text-box-search-outline")]),_vm._v(" Registros ")],1),_c('sub-navigation',{staticClass:"mb-4",attrs:{"routes":_vm.routes}}),_c('Filters',{staticClass:"mb-2",attrs:{"dataFilters":require('@/assets/filters/logs.json')},on:{"apply-filter":_vm.applyFilter}}),_c('section',[_c('v-chip-group',{staticClass:"mb-2",attrs:{"column":""},on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.statusFilter),function(item,i){return _c('v-chip',{key:i,staticClass:"mr-1",attrs:{"color":"transparent","active-class":((item.color) + "--text"),"value":item.value,"small":""}},[_c('v-icon',{attrs:{"color":item.color,"left":"","small":""}},[_vm._v(" mdi-square-rounded ")]),_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1),_c('v-card',{staticClass:"rounded-lg",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"accent pointer-hand",attrs:{"item-key":"_id","headers":_vm.headers,"items":_vm.logs,"items-per-page":_vm.pagination.itemsPerPage,"mobile-breakpoint":0,"item-class":_vm.rowClasses,"custom-sort":_vm.pagination.customSort,"expanded":_vm.expanded,"hide-default-footer":"","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.idMask(item._id))+" ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [(item.subject || item.subjectType)?_c('span',[_c('p',{staticClass:"mb-0 single-line"},[(item.subject)?_c('span',[_vm._v(" "+_vm._s(_vm.idMask(item.subject))+" ")]):_c('span',[_vm._v("~")])]),_c('p',{staticClass:"mb-0 text-caption single-line"},[_vm._v(" "+_vm._s(_vm.getSubject(item.subjectType))+" ")])]):_c('span',[_vm._v("-")])]}},{key:"item.identifier",fn:function(ref){
var item = ref.item;
return [(item.identifier || item.identifierType)?_c('span',[_c('p',{staticClass:"mb-0 single-line"},[(item.identifier)?_c('span',[_vm._v(" "+_vm._s(_vm.idMask(item.identifier))+" ")]):_c('span',[_vm._v("~")])]),_c('p',{staticClass:"mb-0 text-caption single-line"},[_vm._v(" "+_vm._s(_vm.getIdentifier(item.identifierType))+" ")])]):_c('span',[_vm._v("~")])]}},{key:"item.childIdentifier",fn:function(ref){
var item = ref.item;
return [(item.childIdentifier || item.childType)?_c('span',[_c('p',{staticClass:"mb-0"},[(item.childIdentifier)?_c('span',[_vm._v(" "+_vm._s(_vm.idMask(item.childIdentifier))+" ")]):_c('span',[_vm._v("~")])]),_c('p',{staticClass:"mb-0 text-caption single-line"},[_vm._v(" "+_vm._s(_vm.getChild(item.childType))+" ")])]):_c('span',[_vm._v("~")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('span',{staticClass:"secondary_text--text"},[_vm._v(" log: ")]),_c('span',{staticClass:"mr-6"},[_vm._v(" "+_vm._s(item.errorLog || "empty")+" ")]),_c('span',{staticClass:"secondary_text--text"},[_vm._v(" message: ")]),_c('span',[_vm._v(" "+_vm._s(item.errorMessage || "empty")+" ")])])]}}],null,true)})],1),_c('v-pagination',{staticClass:"py-4",attrs:{"color":"primary","length":_vm.pagination.pageCount,"total-visible":10,"circle":""},on:{"input":function($event){return _vm.getData()}},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),(_vm.loading)?_c('loader-hover'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }